import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Chat } from "../Chat";
import { useContext } from "react";
import { LanguageContext } from "../../utils/multiLanguageString";

export type SingleConversation = { from: string, text: string, title?: string, redirect?: string }[];

export const ConversationRouter = () => {
  const { multiLanguageString, getString } = useContext(LanguageContext);

  const homeConversations = {
    start: [
      {
        from: "cm",
        title: multiLanguageString(getString("landing_chat_home1_title")),
        text: multiLanguageString(getString("landing_chat_home1_richtext")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_home2_richtext")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_home3_richtext")),
      },
    ],
    q1: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_menuItem_ticket")),
        redirect: "/unbigliettotremusei",
      },
    ],
    q2: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_menuItem_webapp")),
        redirect: "/storiedilagolaapp",
      },
    ],
    q3: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_menuItem_experience")),
        redirect: "/esperienze",
      },
    ],
  } as { [key: "start" | string]: SingleConversation };

  const ticketConversations = {
    start: [
      {
        from: "cm",
        title: multiLanguageString(getString("landing_chat_ticket1_title")),
        text: multiLanguageString(getString("landing_chat_ticket1_richtext")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_ticket2_richtext")),
      },
    ],
    q1: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_ticket_m1_name")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_ticket_m1_desc_richtext")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_ticket_m1_cta_richtext")),
      },
    ],
    q2: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_ticket_m2_name")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_ticket_m2_desc_richtext")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_ticket_m2_cta_richtext")),
      },
    ],
    q3: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_ticket_m3_name")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_ticket_m3_desc_richtext")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_ticket_m3_cta_richtext")),
      },
    ],
    qx: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_menuItem_back")),
        redirect: "/",
      },
    ],
  } as { [key: "start" | string]: SingleConversation };

  const webappConversations = {
    start:  [
      {
        from: "cm",
        title: multiLanguageString(getString("landing_chat_webapp1_title")),
        text: multiLanguageString(getString("landing_chat_webapp1_1_richtext")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_webapp1_2_richtext")),
      },
    ],
    q2: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_webapp2_title")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_webapp2_richtext")),
      },
    ],
    q3: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_webapp3_title")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_webapp3_richtext")),
      },
    ],
    q4: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_webapp4_title")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_webapp4_richtext")),
      },
    ],
    qx: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_menuItem_back")),
        redirect: "/",
      },
    ],
  } as { [key: "start" | string]: SingleConversation };

  const experienceConversations = {
    start:  [
      {
        from: "cm",
        title: multiLanguageString(getString("landing_chat_experience_title")),
        text: multiLanguageString(getString("landing_chat_experience_1_richtext")),
      },
      {
        from: "cm",
        text: multiLanguageString(getString("landing_chat_experience_2_richtext")),
      },
    ],
    qx: [
      {
        from: "user",
        text: multiLanguageString(getString("landing_chat_menuItem_back")),
        redirect: "/",
      },
    ],
  } as { [key: "start" | string]: SingleConversation };

  const { setCurrentLanguage } = useContext(LanguageContext);

  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Chat conversations={homeConversations} />} />
      <Route path="/privacy-terms" element={
        <div className="chat">
          <div className="padder">
            <div className="inner">
              <div className="privacy-terms">
                <h2>Privacy and cookie policies</h2>
                <div dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("landing_footer_right_richtext")) }} />
              </div>
              <footer style={{ paddingTop: "30px" }}>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("landing_footer_left_richtext")) }} />
                </div>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("landing_footer_right_richtext")) }} />
                  <p></p>
                  <div className="langs">
                    <button onClick={() => {setCurrentLanguage("it"); window.scrollTo(0, 0);}}>Italiano</button>
                    <br/>
                    <button onClick={() => {setCurrentLanguage("en"); window.scrollTo(0, 0);}}>English</button>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      } />
      <Route path="/unbigliettotremusei" element={<Chat conversations={ticketConversations} />} />
      <Route path="/storiedilagolaapp" element={<Chat conversations={webappConversations} />} />
      <Route path="/esperienze" element={<Chat conversations={experienceConversations} />} />
    </Routes>
  </BrowserRouter>;
};
