import { Link, useNavigate } from "react-router-dom";
import { SingleConversation } from "../ConversationRouter";
import collezionista from "./collezionista.png";
import fondazione from "./fondazione-cariplo.png";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../utils/multiLanguageString";

export const Chat = ({ conversations }: { conversations: { [key: "start" | string]: SingleConversation } }) => {
  const [currentConversationKey, setCurrentConversationKey] = useState("start");
  const [currentVisibleConversation, setCurrentVisibleConversation] = useState<SingleConversation>();
  const [playedMessages, setPlayedMessages] = useState<string[]>([]);
  const [messagesVisible, setMessagesVisible] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentConversationKey("start");
    setPlayedMessages([]);
    setCurrentVisibleConversation(conversations.start);
  }, [conversations]);

  useEffect(() => {
    setMessagesVisible(false);
    setPlayedMessages([...playedMessages, currentConversationKey]);
    const padder = document.querySelector(".padder");
    if (padder && currentConversationKey !== "start") {
      const rect = padder.getBoundingClientRect();
      const scrollPos = rect.top + window.scrollY;
      window.scrollTo({ top: scrollPos });
    }

    setTimeout(() => {
      if (conversations[currentConversationKey][0].redirect) {
        navigate(conversations[currentConversationKey][0].redirect as string);
      } else {
        setCurrentVisibleConversation(conversations[currentConversationKey]);
        setTimeout(() => {
          setMessagesVisible(true);
        }, 200);
      }
    }, 1000);
    /* eslint-disable-next-line */
  }, [currentConversationKey, conversations]);

  const { multiLanguageString, getString, setCurrentLanguage } = useContext(LanguageContext);

  return <div id="info" className="chat">
    <div className="padder"></div>
    <div className="inner">
      <div className="wrapper">
        <div className="left">
          <img src={collezionista} alt="" />
          <div>{multiLanguageString(getString("landing_character_name"))} <span>{multiLanguageString(getString("landing_character_subtitle"))}</span></div>
        </div>
        <div className="right chatContainer">
          <div className={`history ${messagesVisible ? "messagesVisible" : "messagesInvisible"}`}>
            {currentVisibleConversation && <>
              {currentVisibleConversation.map((message, index) => <div key={index} className={`message from${message.from}`} dangerouslySetInnerHTML={{ __html: message.title ? `<h2>${message.title}</h2> ${message.text}` : message.text }}></div>)}
            </>}
          </div>
          <div className="answers">
            {conversations && Object.keys(conversations).map((key) => {
              if (key.startsWith("q")) {
                const conversation = conversations[key];
                const userMessage = conversation.find((message) => message.from === "user");
                return userMessage ? <div className='messageWrapper'>
                  {conversations[key][0].redirect 
                    ? <Link className="messageTrigger" onClick={() => {
                      setCurrentConversationKey("start");
                      setPlayedMessages([]);
                      const padder = document.querySelector(".padder");
                      if (padder) {
                        const rect = padder.getBoundingClientRect();
                        const scrollPos = rect.top + window.scrollY;
                        window.scrollTo({ top: scrollPos });
                      }
                    }} to={conversations[key][0].redirect as string}>
                      {userMessage.text}
                    </Link>
                    : <button
                      className={`messageTrigger ${playedMessages.includes(key) ? "done" : ""}`}
                      onClick={() => {setCurrentConversationKey(key);}}
                    >
                      {userMessage.text}
                    </button>}
                </div> : null;
              }

              return null;
            })}
          </div>
        </div>
      </div>
      <footer>
        <div>
          <div dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("landing_footer_left_richtext")) }} />
          <img src={fondazione} alt="" style={{ width: "100%", maxWidth: "150px", marginTop: "1em" }} />
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("landing_footer_right_richtext")) }} />
          <p></p>
          <div className="langs">
            <button onClick={() => {setCurrentLanguage("it"); window.scrollTo(0, 0);}}>Italiano</button>
            <br/>
            <button onClick={() => {setCurrentLanguage("en"); window.scrollTo(0, 0);}}>English</button>
          </div>
        </div>
      </footer>
    </div>
  </div>;
};
