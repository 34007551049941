import "./App.scss";
import { LanguageWrapper } from "./utils/multiLanguageString";
import { Splash } from "./components/Splash";
import { ConversationRouter } from "./components/ConversationRouter";

function App() {
  return (
    <LanguageWrapper>
      <div className="App">
        <Splash />
        <ConversationRouter />
      </div>
    </LanguageWrapper>
  );
}

export default App;
