import { useContext } from "react";
import { LanguageContext } from "../../utils/multiLanguageString";
import logo from "./logo.svg";

export const Splash = () => {
  const { multiLanguageString, getString } = useContext(LanguageContext);

  return <div className="splash">
    <div className="top">
      <div className="logo-wrapper">
        <img src={logo} alt="" />
      </div>
      <div className="tag">
        <span>{multiLanguageString(getString("landing_title"))} </span>
        <span>{multiLanguageString(getString("landing_subtitle1"))} </span>
        <span>{multiLanguageString(getString("landing_subtitle2"))}</span>
      </div>
    </div>
    <div className="bottom">
      <a href="#info">{multiLanguageString(getString("landing_explore_cta"))}</a>
    </div>
  </div>;
};
